import {
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	HStack,
	Tooltip,
	IconButton,
	Button,
	ModalFooter,
	Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FiPlus, FiX } from 'react-icons/fi';
import { chakraSelect } from '../../../../../utils/styleConstants';
import { AsyncPaginate } from 'react-select-async-paginate';

interface EditAreaModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: any;
	refetchData: () => void;
}

export const EditAreaModalForm: React.FC<EditAreaModalFormProps> = ({
	url,
	isOpen,
	onClose,
	selectedItem,
	refetchData,
}) => {
	const initialValues = {
		name: selectedItem ? selectedItem.name : '',
		city_id: selectedItem && selectedItem.city ? selectedItem.city.id : '',
		zone_id: selectedItem && selectedItem.zone ? selectedItem.zone.id : '',
		geocoordinates: selectedItem
			? selectedItem.geocoordinates
			: [
					{
						long: '',
						lat: '',
					},
			  ],
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Area name is required'),
		city_id: Yup.number().required('City is required'),
		zone_id: Yup.string(),
		geocoordinates: Yup.array().of(
			Yup.object().shape({
				lat: Yup.number().required('Latitude is required'),
				long: Yup.number().required('Longitude is required'),
			})
		),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (values, { setStatus }) => {
			try {
				await axios.put(`${url}/${selectedItem.id}`, {
					...values,
					zone_id: parseInt(values.zone_id),
				});
				toast({
					description: 'Item updated successfully',
					status: 'success',
				});
				resetAndCloseAddModal();
				refetchData();
			} catch (err: any) {
				if (err.response.status !== 401 && err.response.status !== 420) {
					setStatus(err.response.data.message || 'An error occurred');
				}
			}
		},
	});

	const [cityKeyword, setCityKeyword] = useState('');
	const [selectedCity, setSelectedCity] = useState(null);
	const [cityPage, setCityPage] = useState(1);
	const [citySearchPage, setCitySearchPage] = useState(1);

	const [zoneKeyword, setZoneKeyword] = useState('');
	const [selectedZone, setSelectedZone] = useState(null);
	const [zonePage, setZonePage] = useState(1);
	const [zoneSearchPage, setZoneSearchPage] = useState(1);

	const loadCityOptions = () => {
		let limit = 10;
		let currentPage = cityKeyword ? citySearchPage : cityPage;
		let params: any = {
			pagination: { page: currentPage, limit },
		};
		if (cityKeyword) {
			params.filter = { keyword: cityKeyword };
		}
		return axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/city`, {
				params,
			})
			.then((res) => {
				let hasMore = res.data.total - currentPage * limit > 0;
				if (hasMore) {
					cityKeyword
						? setCitySearchPage((p) => p + 1)
						: setCityPage((p) => p + 1);
				}
				return {
					options: res.data.data,
					hasMore,
				};
			})
			.catch((err) => {
				return { options: [] };
			});
	};

	const loadZoneOptions = () => {
		let limit = 10;
		let currentPage = zoneKeyword ? zoneSearchPage : zonePage;
		let params: any = {
			pagination: { page: currentPage, limit },
		};
		if (zoneKeyword) {
			params.filter = { keyword: zoneKeyword };
		}
		return axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/zone`, {
				params,
			})
			.then((res) => {
				let hasMore = res.data.total - currentPage * limit > 0;
				if (hasMore) {
					zoneKeyword
						? setZoneSearchPage((p) => p + 1)
						: setZonePage((p) => p + 1);
				}
				return {
					options: res.data.data,
					hasMore,
				};
			})
			.catch((err) => {
				return { options: [] };
			});
	};

	useEffect(() => {
		if (selectedItem) {
			setSelectedCity(selectedItem.city);
			setSelectedZone(selectedItem.zone);
		}
	}, [selectedItem]);

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
		setZonePage(1);
		setZoneSearchPage(1);
		setCityPage(1);
		setCitySearchPage(1);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<ModalContent overflow='auto'>
				<ModalHeader>Edit Area</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.name} mb='4'>
							<FormLabel>Name</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.city_id} mb='4'>
							<FormLabel>City</FormLabel>
							<AsyncPaginate
								debounceTimeout={300}
								defaultOptions
								getOptionLabel={(option: any) => option.name}
								getOptionValue={(option: any) => option.id}
								loadOptions={loadCityOptions}
								inputValue={cityKeyword}
								onInputChange={(v: any) => {
									setCityKeyword(v);
									setCitySearchPage(1);
								}}
								value={selectedCity}
								onChange={(v: any) => {
									setSelectedCity(v);
									formik.setFieldValue('city_id', v.id);
								}}
								menuPosition='fixed'
								styles={{
									control: (existingStyles) => {
										return {
											...existingStyles,
											chakraSelect,
										};
									},
								}}
							/>
							<FormErrorMessage>{formik.errors.city_id}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.zone_id} mb='4'>
							<FormLabel>Zone</FormLabel>
							<AsyncPaginate
								debounceTimeout={300}
								defaultOptions
								getOptionLabel={(option: any) => option.name}
								getOptionValue={(option: any) => option.id}
								loadOptions={loadZoneOptions}
								inputValue={zoneKeyword}
								onInputChange={(v: any) => {
									setZoneKeyword(v);
									setZoneSearchPage(1);
								}}
								value={selectedZone}
								onChange={(v: any) => {
									setSelectedZone(v);
									formik.setFieldValue('zone_id', v.id);
								}}
								menuPosition='fixed'
								styles={{
									control: (existingStyles) => {
										return {
											...existingStyles,
											chakraSelect,
										};
									},
								}}
							/>
							<FormErrorMessage>{formik.errors.zone_id}</FormErrorMessage>
						</FormControl>
						<FormikProvider value={formik}>
							<FieldArray
								name='geocoordinates'
								render={(arrayHelpers) => (
									<>
										<Text fontWeight='semibold' mb='4'>
											geocoordinates
										</Text>
										{formik.values.geocoordinates &&
											formik.values.geocoordinates.length > 0 &&
											formik.values.geocoordinates.map(
												(val: any, index: number) => {
													return (
														<HStack
															justifyContent='space-between'
															alignItems='start'
															spacing={2}
															key={index}
															mb='4'
														>
															<FormControl
																isInvalid={
																	formik.errors.geocoordinates &&
																	(formik.errors.geocoordinates as any)[
																		index
																	] &&
																	(formik.errors.geocoordinates as any).lat
																}
															>
																<FormLabel>Latitude</FormLabel>
																<Input
																	{...formik.getFieldProps(
																		`geocoordinates.${index}.lat`
																	)}
																	type='number'
																/>
																<FormErrorMessage>
																	{formik.errors.geocoordinates &&
																		(formik.errors.geocoordinates as any) &&
																		(formik.errors.geocoordinates as any).lat}
																</FormErrorMessage>
															</FormControl>
															<FormControl
																isInvalid={
																	formik.errors.geocoordinates &&
																	(formik.errors.geocoordinates as any) &&
																	(formik.errors.geocoordinates as any).lat
																}
															>
																<FormLabel>Longitude</FormLabel>
																<Input
																	{...formik.getFieldProps(
																		`geocoordinates.${index}.long`
																	)}
																	type='number'
																/>
																<FormErrorMessage>
																	{formik.errors.geocoordinates &&
																		(formik.errors.geocoordinates as any) &&
																		(formik.errors.geocoordinates as any).lat}
																</FormErrorMessage>
															</FormControl>
															{
																<Tooltip label='Delete'>
																	<IconButton
																		aria-label='remove lat-long'
																		colorScheme='red'
																		icon={<FiX />}
																		onClick={() => arrayHelpers.remove(index)}
																		size='sm'
																		disabled={
																			formik.values.geocoordinates &&
																			formik.values.geocoordinates.length <= 1
																		}
																	/>
																</Tooltip>
															}
														</HStack>
													);
												}
											)}
										<Button
											rightIcon={<FiPlus />}
											size='sm'
											colorScheme='green'
											onClick={() =>
												arrayHelpers.push({
													lat: '',
													long: '',
												})
											}
										>
											Add More
										</Button>
									</>
								)}
							/>
						</FormikProvider>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
