import {
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	ModalFooter,
	Button,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { AsyncPaginate } from 'react-select-async-paginate';
import { chakraSelect } from '../../../../../utils/styleConstants';

interface AddZoneModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	refetchData: () => void;
}

export const AddZoneModalForm: React.FC<AddZoneModalFormProps> = ({
	url,
	isOpen,
	onClose,
	refetchData,
}) => {
	const initialValues = {
		name: '',
		site_id: '',
		min_order_value: '',
		shipping_charges: '',
		express_delivery_charge: '',
		free_ship_value: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('City is required'),
		site_id: Yup.string().required('Site is required'),
		min_order_value: Yup.number().required(),
		shipping_charges: Yup.number().required(),
		express_delivery_charge: Yup.number().required(),
		free_ship_value: Yup.number().required(),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.post(`${url}`, { ...values })
				.then((res) => {
					toast({
						description: 'Item added successfully',
						status: 'success',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const [siteKeyword, setSiteKeyword] = useState('');
	const [selectedSite, setSelectedSite] = useState(null);
	const [sitePage, setSitePage] = useState(1);
	const [siteSearchPage, setSiteSearchPage] = useState(1);

	const loadSiteOptions = () => {
		let currentPage = siteKeyword ? siteSearchPage : sitePage;
		let limit = 10;
		let params: any = {
			pagination: { page: currentPage, limit },
		};
		if (siteKeyword) {
			params.filter = { keyword: siteKeyword };
		}
		return axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/sites`, {
				params,
			})
			.then((res) => {
				let hasMore = res.data.total - currentPage * limit > 0;
				if (hasMore) {
					siteKeyword
						? setSiteSearchPage((p) => p + 1)
						: setSitePage((p) => p + 1);
				}
				return {
					options: res.data.data,
					hasMore,
				};
			})
			.catch((err) => {
				return { options: [] };
			});
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
		setSitePage(0);
		setSiteSearchPage(0);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Zone</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.name} mb='4'>
							<FormLabel>Zone</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.site_id} mb='4'>
							<FormLabel>Site</FormLabel>
							<AsyncPaginate
								debounceTimeout={300}
								defaultOptions
								getOptionLabel={(option: any) => option.website_name}
								getOptionValue={(option: any) => option.id}
								loadOptions={loadSiteOptions}
								inputValue={siteKeyword}
								onInputChange={(v: any) => {
									setSiteKeyword(v);
									setSiteSearchPage(1);
								}}
								value={selectedSite}
								onChange={(v: any) => {
									setSelectedSite(v);
									formik.setFieldValue('site_id', v.id);
								}}
								menuPosition='fixed'
								styles={{
									control: (existingStyles) => {
										return {
											...existingStyles,
											chakraSelect,
										};
									},
								}}
							/>
							<FormErrorMessage>{formik.errors.site_id}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.min_order_value} mb='4'>
							<FormLabel>Minimum cart value</FormLabel>
							<Input {...formik.getFieldProps('min_order_value')} />
							<FormErrorMessage>
								{formik.errors.min_order_value}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.shipping_charges} mb='4'>
							<FormLabel>Shipping charges</FormLabel>
							<Input {...formik.getFieldProps('shipping_charges')} />
							<FormErrorMessage>
								{formik.errors.shipping_charges}
							</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={!!formik.errors.express_delivery_charge}
							mb='4'
						>
							<FormLabel>Express shipping charges</FormLabel>
							<Input {...formik.getFieldProps('express_delivery_charge')} />
							<FormErrorMessage>
								{formik.errors.express_delivery_charge}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.free_ship_value} mb='4'>
							<FormLabel>Minimum cart value for free shipping</FormLabel>
							<Input {...formik.getFieldProps('free_ship_value')} />
							<FormErrorMessage>
								{formik.errors.free_ship_value}
							</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
