import { AxiosStatic } from 'axios';
import {
	getAccessToken,
	removeAccessToken,
	getRefreshToken,
	setAccessToken,
} from './utils/authFunctions';
import { logout } from './utils/logoutFunction';

export function setupAxios(axios: AxiosStatic, toast: any) {
	axios.interceptors.request.use(
		(config) => {
			const accessToken = getAccessToken();
			if (
				accessToken &&
				!config?.headers?.Authorization &&
				!config.url?.includes('amazonaws')
			) {
				config.headers!.Authorization = `Bearer ${accessToken}`;
			}
			if (config.url?.includes('stag-homepage')) {
				config.headers!.Authorization = `Bearer ${`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZXN0b3VzZXJAZ21haWwuY29tIiwicm9sZXMiOlszXSwiZXhwIjoxNjgzMjMzMTM4fQ.ef0TuyzOEFhjtcc1KRIZo3AupD-E5MS0BCXSPf7Ky0c`}`;
			}

			return config;
		},
		(err) => Promise.reject(err)
	);
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (err) {
			const originalRequest = err.config;
			if (
				err.response.status === 401 &&
				originalRequest.url.includes('token')
			) {
				removeAccessToken();
				logout();
				toast({
					description: err.response?.data?.message || 'An error occurred',
					status: 'error',
				});
				return Promise.reject(err);
			}
			if (err.response?.status === 401) {
				const result = await axios.post(
					`${process.env.REACT_APP_UMS_BASE_URL}/admin/token`,
					{ refreshToken: getRefreshToken() }
				);
				setAccessToken(result.data.data.token);
				originalRequest.headers['Authorization'] =
					'Bearer ' + result.data.data.token;
				return axios(originalRequest);
			}
			return Promise.reject(err);
		}
	);
}
